const thesisSections = [
  {
    title: "determination",
    mainImage: "/images/determination.jpg",
    gallery: [
      "/images/determination-1.jpg",
      "/images/determination-2.jpg",
      "/images/determination-3.jpg",
      "/images/determination-4.jpg",
      "/images/determination-5.jpg",
    ],
    galleryAnnotations: [
      "In a Priory Varsity Hockey game against Ladue on November 22nd, 2024, Freddy Gaertner '26 handles the puck through the Ladue defense. That is until he was tripped by a Ladue player who is out of frame. Even after being tripped, seemingly floating in the air, still has his eyes focused on moving forward. To capture this shot, I made sure my camera was shooting at a high shutter speed to freeze the action, specifically, it was at 1/800s!",
      "Quite possibly one of my favorite photos that I have ever taken depicts Nate Sutto '26, a goalie, has his eyes locked on to the falling puck in a puck-drop between Priory's number 76, JP Nadreau '25, and Clayton's number 12. This picture was taken back on November 11, 2023, and even though I didn't study sports photography as I do now, I was able to capture one of the best shots of the year. What makes this picture so great from a technical perspective is most importantly the framing of the image. To make this picture great, I made sure that Nate was positioned in the middle of the frame (though the rule of thirds was not followed, I believe that it should be except in this particular photo) and in the foreground, blurred, are the two players going head to head.",
      "In a thriller of a game on February 24, 2024, the Priory Varsity Basketball team ended up winning their first district championship since 1956! In this game, the amount of determination, comradery, and pure grit was put on display. In this picture is one of the pivotal players on the team Robby Temprano '26, who was one of the point leaders on the team during this historic season, dribbles down the court while a Whitfield player is determined to stop him. While I wouldn't consider this picture great, I would consider it good. The good comes from the facial expressions that I have captured in this frame; however, this picture loses some points in my book because the right hand of the Whitfield player has been cut out of frame. In sports photography, you want to ensure that all limbs are in the picture.",
      "During a incredible game that the Ravens ended up winning, the Priory Ravens faced off against the Ladue Rams. With Nate Sutto '26 in goal, the Ravens hope to take down the Rams and give them their first loss in the Central Division. In this picture, Sutto has just made an easy save and is determined to figure out what he will do with the puck next. Since there was plenty of time, Nate ended up dishing the puck back out to Charlie Waltz '28 in the foreground. To capture this picture, I used the long focal length of my Sigma 60-600mm lens and zoomed in to make sure that just Nate was in the frame. To make sure that the picture was not taken from eye level, I stood on the bench with the coaches to get a higher vantage point to take my shots. - Camera Settings: ISO 5000 240mm f/5.6 1/800sec",
      "After I rolled my ankle the day before, I asked my coach if it would be all right if I could take pictures during the game as the trainer said I was unable to play to let my injury heal. After he agreed, I was extremely happy as I have never gotten to take soccer pictures for a team that I play on! I also have never really taken soccer photography besides some work I did at STL SC for an article. Around the start of the game, the sun was still out, and providing a significant amount of light allowing for really nice pictures; however, near the end of the game the sun went down VERY fast and I had to make quick adjustments to allow the photos to turn out well. In this picture, we can see Jack Mantovani '26 making a long pass across the field. I chose this picture for the determination section because you can see his facial expression and determination to make the pass. What also makes this picture great is the turf being kicked up where his foot made contact with the ball. To capture that, I made sure my shutter speed was extremely high to make the effect of time being frozen. - Camera Settings: ISO 320 600mm f/6.3 1/1600sec",
    ],
    annotation:
      "To me, a great shot that embodies determination in sports photography requires a diverse set of skills. Capturing an athlete's pure grit and perseverance involves getting up close to the action while remaining off the field, rink, or court. For this, I rely on my Sigma 60-600mm lens, which offers outstanding versatility in sports photography. This lens allows me to capture incredibly detailed close-up shots, showcasing the intensity and passion in an athlete's expression, while also giving me the ability to zoom out sufficiently to encompass the broader scene and all of the unfolding action. \n In this section, you will find a selection of some of my favorite photographs featuring Priory athletes, each highlighting their unwavering determination to win. These images encapsulate the moments that truly define dedication and hard work in sports. To dive deeper into the story behind each smaller image, simply click on the image itself for annotations and insights on what makes each shot unique and meaningful. I hope you enjoy viewing these moments as much as I enjoyed capturing them!",
  },
  {
    title: "excitement",
    mainImage: "/images/excitement.jpg",
    gallery: [
      "/images/excitement-1.jpg",
      "/images/excitement-2.jpg",
      "/images/excitement-3.jpg",
      "/images/excitement-4.jpg",
      "/images/excitement-5.jpg",
    ],
    galleryAnnotations: [
      "This photograph almost perfectly captures the essence of excitement in sports. Taken on November 25, 2024, during a Priory Varsity Hockey game against Fort Zumwalt West, Freddy Gaertner '26 leaps into the glass in celebration after scoring a pivotal goal. Surrounding him, Priory fans erupt in cheers, their energy and passion mirroring Gaertner's joy. To the left, teammate Auggie Werner '27 skates in, celebrating alongside him and adding a layer of camaraderie to the image. What makes this photo remarkable to me is the raw emotion from both the players and the most noticeable, the crowd. I ensured my camera settings were optimized to capture the high-intensity action—using a fast shutter speed of 1/800s to freeze Gaertner mid-air and highlight the dynamic interaction between the athlete and his ecstatic supporters.",
      "This shot captures Wynn O'Keefe '25 celebrating one of his first-ever varsity hockey goals in a game against Northwest on January 11, 2024. The mere excitement on Wynn’s face, with his glove raised high in victory, says it all. To his right, teammates Jack Lerits '25, Jake Waltz '25, and Cameron Robinson '25 stand near celebrating with him, their smiles beaming with pride and energy. What makes this picture stand out to me is the moment's purity—Wynn’s joy, supported by his teammates, tells a story of hard work and achievement. I used a fast shutter speed of 1/800s to ensure the players were sharply captured, freezing this perfect moment of celebration. The composition draws attention to Wynn as the focal point, but the inclusion of his teammates and the opposing goalie adds depth to the image, balancing the emotion on both sides of the play.",
      "Under the Friday night lights of September 8, 2023, Jack Parent '25 celebrates in pure adrenaline after scoring on a quarterback keeper in a game against Lutheran South. Front and center in the picture, Jack screams with raw emotion, flexing his forearms confidence, strength, and energy. The brighter backdrop of the night game makes the moment feel larger than life, with Jack acting as a silhouette.",
      "During the second to last regular season game before the Wickenheiser Cup playoffs, the Priory Ravens faced off against the Clayton Greyhounds. The game was one that had lots of importance to the team, as the winner of this game would get a first-round bye in the playoffs. In this picture, we can see JP Nadreau '25 screaming with excitement after scoring a goal only a few seconds after Max Nadaud '26 had also scored, making the score tied at 2-2. What makes this picture so incredible to me is the amount of emotion that you can see from JP. To capture this moment, I made sure that my Sigma 60-600mm lens was zoomed in just enough to capture JP with no other distractions. There are just a few things that I would change about this picture: first, it is slightly out of focus but I decided to keep the picture anyway just because of the amount of emotion captured. Secondly, I would have liked to have more padding between JP and the edge of the frame. To do this, I should have just zoomed out more. - Camera Settings: ISO 4000 130mm f/5 1/800sec",
      "After I rolled my ankle the day before, I asked my coach if it would be all right if I could take pictures during the game as the trainer said I was unable to play to let my injury heal. After he agreed, I was extremely happy as I have never gotten to take soccer pictures for a team that I play on! I also have never really taken soccer photography besides some work I did at STL SC for an article. Around the start of the game, the sun was still out, and providing a significant amount of light allowing for really nice pictures; however, near the end of the game the sun went down VERY fast and I had to make quick adjustments to allow the photos to turn out well. After scoring the first goal of the game against Bayless on October 7th, 2024, Mick Murphy '25 finishes celebrating with his teammates and then turns to me in excitement holding up a shaka sign. To capture this moment, I first told the team that if they scored to do some sort of motion to the camera explain where I would be sitting. Next, I then made sure that I was shooting from below eye level to make sure that the subject's body was not disproportioned. - Camera Settings: ISO 1100 170mm f/5.3 1/1250sec",
    ],
    annotation:
      "Quite possibly one of my favorite categories to get a picture of is excitement. Immediately after checking my camera once an exciting moment happens in a game, I am very quickly able to tell if it will be great. A picture that embodies excitement should show the athletes (and/or his teams) pure joy after scoring a goal or making a good shot. One of the most important aspects of being able to make a good picture great is to capture the emotion in the moment. In this section, you will find a selection of some of my favorite photographs featuring Priory athletes, each highlighting their pure joy in the sports they play. These images encapsulate the moments that mean so much to the athletes and the fans themselves. To dive deeper into the story behind each smaller image, simply click on the image itself for annotations and insights on what makes each shot unique and meaningful. I hope you enjoy viewing these moments as much as I enjoyed capturing them!",
  },
  {
    title: "teamwork",
    mainImage: "/images/teamwork.jpg",
    gallery: [
      "/images/teamwork-1.jpg",
      "/images/teamwork-2.jpg",
      "/images/teamwork-3.jpg",
      "/images/teamwork-4.jpg",
      "/images/teamwork-5.jpg",
    ],
    galleryAnnotations: [
      "In a thriller of a game on April 14, 2023, against CBC, the Ravens played an extremely close match, with each team trading goals back and forth. In this picture, Jack Parent '25 has just finished a dodge down the right alley and is passing the ball over to Mick Murphy '25, who is cutting toward him for a shot. The best technical aspect of this picture is how the ball is frozen mid-flight, showing the pass on its way to Mick. However, as this was only my second year of photography, I didn't quite understand many of the technical aspects of the craft. If I were to take this picture again, I would make sure that all the athletes' limbs are in frame or fully commit to an upper-body shot. Additionally, the rule of thirds is not being followed, so I would adjust the framing to improve the composition.",
      "In this picture, Liam Kallos '29 is dribbling down the field in the CYC Championship game. In the foreground is Caleb Albers '29, checking to the ball in an attempt to receive a pass to advance the play, which is why I chose this picture to represent the teamwork section. To capture this image, I was seated behind the goal that the Ravens were attacking on, by taking a low vantage point, I was able to capture a more level image, which overall makes the picture look better as you do not want to be shooting just at eye level in sports photography as shooting as doing so tends to distort portions of the body. Another key factor that played into making this a good picture, was the lighting on the day. Despite this picture being taken on November 28th, 2024 it was still a nice warm day with plenty of sun. The abundance of sunlight helped me keep my shutter speed extremely high, while also keeping my ISO very low. These two things together allow me to capture a image that is frozen in time, while not being grainy. - Photo settings: ISO 900 360.0mm f/6 1/2000sec",
      "This might just be one of the best football pictures that I have taken.  On August 20th, 2024 the Priory Ravens Varsity football team played their first game of the season against Jefferson. Although the game ended in an unfortunate loss, I was able to capture some incredible pictures such as this one. To capture this picture, I was literally sprawled out on the ground shooting between the line judge's legs. I did this to capture the scene at the line right before the ball is snapped. I chose this image for the teamwork section because the offensive line on a football team is the most essential part of the team. Without the O-Line, nothing else would be able to work as the quarterback would be immediately sacked. The technical aspects that were able to make this picture great in my opinion are a combination of the angle I took the shot at (which was aforementioned) along with the very sunny August day which allowed me to have a great shutter speed with a low ISO. If I were to change one thing about this picture, I would have liked to shoot more from the side so I could attempt to capture the emotion in the offensive lines eyes, I believe if I was able to do that, the picture would be perfect. - Photo Settings: ISO 320 280mm f/5.6 1/1000sec",
      "During the Sports Media Club's first annual Salute to Service Football Game on October 5th, 2024 the Priory Ravens faced off against the John Burroughs Bombers. A few weeks earlier in the Priory vs. Westminster game starting quarterback Jack Parent '25 tore his MCL, and so backup quarterback Gavin Gittemier '26 was the starting quarterback. To make sure that the team had the best chance at winning, the whole team needed to play as one. In the picture, you can see Gavin falling back into the pocket, while the offensive line blocks to give Gavin time to make a play. To capture this moment, I utilized the rule of thirds, along with the picture from a low vantage point to make the picture look better. The one thing I would change about this picture is the leveling. To an untrained eye, the picture might look fine, but I can tell that this picture isn't completely leveled. - Camera Settings: ISO 250 120mm f/5 1/1600sec",
      "In this picture, Alex Buckoale '27 is dribbling down the field during a game against Bayless in which I was not playing due to an ankle injury. Raphi Clark '27 is ready to receive a pass with his hands wide open, which is why I chose this image for the teamwork section. I captured the photo from behind the goal, using a low vantage point to achieve a more level image. This technique improved the picture's quality by avoiding distortion from shooting eye level. The excellent lighting that day also contributed to the photo's clarity. The bright sunlight allowed for a high shutter speed and low ISO, resulting in a sharp, grain-free image. - Camera Settings: ISO 640 340mm f/6 1/1250sec",
    ],
    annotation:
      "To have a great team, you can't just have great players; you need strong team chemistry as well. Capturing these moments is incredibly meaningful to both myself and the players alike. To capture these moments, I try to keep my focal length shorter to capture more of the team itself rather than one individual. This combined with a high shutter speed and low ISO allows for the pictures to turn out frozen in time, and not grainy! In this section, you will find a selection of some of my favorite photographs featuring Priory athletes working together, each highlighting how working as a team will benefit everybody. These images encapsulate the moments that mean so much to the athletes and the fans themselves. To dive deeper into the story behind each smaller image, simply click on the image itself for annotations and insights on what makes each shot unique and meaningful. I hope you enjoy viewing these moments as much as I enjoyed capturing them!",
  },
  {
    title: "victory",
    mainImage: "/images/victory.jpg",
    gallery: [
      "/images/victory-1.jpg",
      "/images/victory-2.jpg",
      "/images/victory-3.jpg",
      "/images/victory-4.jpg",
      "/images/victory-5.jpg",
    ],
    galleryAnnotations: [
      "After I rolled my ankle the day before, I asked my coach if it would be alright if I could take pictures during the game as the trainer said I was unable to play in order to let my injury heal. After he agreed, I was extremely happy as I have never gotten to take soccer pictures for a team that I play on! I also have never really taken soccer photography besides some work I did at STL SC for a article. Around the start of the game, the sun was still out, and providing a significant amount of light allowing for really nice pictures; however, near the end of the game the sun went down VERY fast and I had to make quick adjustments to allow the photos to turn out well. After the game concluded and the Priory Ravens ended up winning, the team walked over to the fans who stayed and supported the team throughout the game. To capture this moment of victory, I turned down my shutter speed to allow the ISO to go lower and create a less grainy picture. I was able to lower my shutter speed as the athletes were not moving fast, but rather just walking over to the fans. - Camera Settings: ISO 4500 60mm f/4.5 1/640sec",
      "As I mentioned in the introduction to this section, I have created a tradition with the Varsity Hockey team. After every game we win, I hop on the ice and round up some people to take a picture holding their hands in a W. I could provide many pictures of this post game tradition, but I chose this one because Freddy Gaertner '26 is laying on the ground along with Thomas Kraeger '26 the team manager crouching down, making for a more balanced picture. After the game concludes, I switch to my Sigma 24-70 f/2.8 lens to get a really low f-stop which allows for a low ISO. I  chose this picture because it was taking after the conclusion of the Priory vs. Ladue game on December 27th, 2024 where the Ravens gave the Rams their first lost in the central division. - Camera Settings: ISO 800 30mm f/2.8 1/800sec",
      "On November 18th, 2024 the Priory Ravens took down the Westminster Wildcats in a not-so-close game, but none-the-less it was still a rivalry game. In the final minutes, the Wildcats ended up pulling their goalie in an attempt to score a goal or two. However, their attack fizzled out, and the Ravens got possession of the puck back. The puck was passed to Freddy Gaertner '26 on the right side of the ice, who launches it down towards the open goal of the Westminster Wildcats. As the puck sails past the goal line, the referee blows his whistle, and Freddy celebrates to the ecstatic crowd. What makes this picture so good, is the referee in the foreground doing the hand motion for a goal being scored. And in focus, is Freddy celebrating with the fans. - Camera Settings: ISO 2500 105mm f/5.4 1/800sec",
      "With the game ending in a victory for the Ravens, the team celebrates after taking down Principia on homecoming day. To capture this image, I switched to my 18-55mm lens to get a slight fisheye effect by keeping the lens zoomed out at an 18mm focal length. Another trick I used was to flip my LCD view screen down and turn on the live feed. I used this to get the camera above the huddle with a slight downward tilt, to capture the moment of victory. Camera Settings: ISO 720 18mm f/4.5 1/3200sec",
      "After making a bet with our math teacher, Mr. Archibald, that he would try out wrestling for a few weeks, Colin Pim '25 stepped onto the mat for his first-ever wrestling meet on December 6, 2024, at the John Burroughs Meet. After weeks of practice, Colin found himself in his first match—and eventually came out victorious. In this picture, we see him standing tall, his arm raised in triumph by the referee. What makes this picture particularly powerful is the contrast between Colin’s composed expression and the defeated stance of his opponent. To capture this moment, I made sure to use a fast shutter speed to freeze the action, while my Sigma 24-70mm f/2.8 lens allowed for sharp focus and a shallow depth of field, ensuring that Colin remained the clear focal point of the image. - Camera Settings: ISO 6400 58mm f/2.8 1/500sec",
    ],
    annotation:
      "Ah yes, victory! Every athletes favorite part of the sport. Along with this, it is one of my favorite categories to capture. This is because the attitude in the venue just is overwhelmed with excitement, pride, and joy. To capture this, I have created with a tradition with the hockey team this year where after every win, I hop on the ice and capture a picture of the team holding up a 'W' with their hands. In this section, you will find a selection of some of my favorite photographs featuring Priory athletes in their winningest moments. These images capture the moments that mean so much to the athletes and the fans themselves. To dive deeper into the story behind each smaller image, simply click on the image itself for annotations and insights on what makes each shot unique and meaningful. I hope you enjoy viewing these moments as much as I enjoyed capturing them!",
  },
  {
    title: "leadership",
    mainImage: "/images/leadership.jpg",
    gallery: [
      "/images/leadership-1.jpg",
      "/images/leadership-2.jpg",
      "/images/leadership-3.jpg",
      "/images/leadership-4.jpg",
      "/images/leadership-5.jpg",
    ],
    galleryAnnotations: [
      "Even though this isn't a sports photography image, I believe it captures an image of one Jimmy Holmes, a Priory Alumni, All-American, and now an assistant coach for the Priory Varsity Soccer team. Coach Holmes is a role model to all of us on the soccer team, he is a figure of leadership during hard workouts or difficult times during games. He is always pushing us to be the best versions of ourselves that we can be. For example, on the last Sunday before the beginning of the season, we have an infamous 'track day' where all you do for two and a half hours is run. Since I am not the fastest person, I typically fall into the back of the pack and Coach Holmes runs almost every drill with us and pushes us to meet the required time and stay on pace. To capture this picture, I made sure that it was being taken looking up at Coach, along with positioning him off-center of the frame to follow the rule of thirds. Camera Settings: ISO 100 70mm f/4.5 1/800sec",
      "While this image may not be one of my best, matter of fact, it isn't even of a Priory sporting event. However, I want to include this image on this website because it tells a story of a life that was taken way too early. Seen jumping into the arms of his teammate, number 72, Colin Brown - a Junior at CBC who plays as number 3 on their hockey team, but number 72 on his club team (pictured) the Affton Americans. While I did not know Colin, I came to the game excited to take pictures at a high-level club hockey game with Max Nadaud '26, who was injured and sat with me the entire game. He told me a lot about his team, but specifically about number 72, who they had nicknamed Brownie. Just a week later, Colin Brown was hit by a stray bullet while leaving one of his hockey games, and a few days later succumbed to his injuries. May Colin Brown rest in peace. ❤️🤍💙/💜💛",
      "On November 24th, 2024, the Priory Ravens CYC Soccer team faced off against St. Ann in the 8th Grade CYC Soccer Championship. Every team needs a great coach. Through brief talks with their coach, Chad Blatz, I concluded that he was a great example of a leader who most definitely wanted his players to succeed. During this game, and especially during warmups, I was experimenting with wide-angle shots using my 18-55mm lens. Throughout warmups, I took individual shots of almost every player kicking the ball, checking for a pass, and even the goalie making a diving save. Once warmups concluded, I thought to myself that taking a picture from below the huddle looking up would turn out quite well. So once the huddle started, I squeezed myself through a few players, put my camera on the grass, and snapped the picture at an 18mm focal length hoping to capture the right moment. Camera Settings: ISO 1000 18mm f/5 1/1250sec",
      "Arguably one of the best moments of leadership that I have captured, you can see Jack Parent '25 speaking to Gavin Gittemier '26 before about plays and tactics he should use during the game. The reason that Jack is talking to Gavin is because just a few weeks earlier during the Priory vs. Westminster game, Jack ended up coming out of the game early with a torn MCL. I believe that this moment encapsulates what it means to be a leader along with a big brother to your underclassmen. To capture this image, I was close up to the subjects, while using my 18-55mm lens specifically the 18mm focal length to provide a slight fisheye effect which adds great depth to the image. To me, this image is near perfect with the only thing I would change being having stood just a little further away to capture all of Jack's arm. Camera Settings: ISO 280 18mm f/3.5 1/1600sec",
      "During the game against Bayless, where I was sidelined with an ankle injury, I had the opportunity to capture moments that reflected the spirit of Priory soccer from a different perspective. In this image, a longtime coach and current head coach of Priory soccer, John Mohrmann, addresses the team during halftime. His immersed expression and body language embody leadership as he provides guidance and encouragement to his players. In the foreground, out of focus to the left, is Charlie Cusumano '27, listening closely. This framing technique helps to emphasize the perspective of the players while keeping Coach Mohrmann as the focal point. To capture this moment, I used a shallow depth of field to isolate the subject while still including elements of the team, reinforcing the theme of leadership and mentorship.",
    ],
    annotation:
      "Every team needs a great leader, or just great leadership in general. While I may not have the most pictures of this section I believe that it will add something special to my thesis. To capture these images of leadership I use a combination of both my Sigma 60-600mm and my Sigma 24-70mm lenses. Through the use of these lenses, I can choose between really close up sports shots with the 60-600. Or really sharp pictures with great bokeh with the 24-70mm f/2.8 lense. In this section, you will find a selection of some of my favorite photographs featuring the leaders throught Priory athletics, each of which depicting just how much the leaders of these teams want the team as a whole to improve. I hope that these images can showcase what it means to be a great leader in high school sports. To dive deeper into the story behind each smaller image, simply click on the image itself for annotations and insights on what makes each shot unique and meaningful. I hope you enjoy viewing these moments as much as I enjoyed capturing them!",
  },
];

export default thesisSections;
